import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const APP_PLATFORM = 'webapp';
export const APP_VERSION = process.env.VERSION;
export const APP_COMMIT_HASH = BUILD_CONFIG.commitShortHash;

// STATE
const state = () => ({
	seoUser: true,
	xServeTrack: null as string | null,
});

export type AppState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<AppState, any> = {
	isSeoUser(state): boolean {
		return state.seoUser;
	},
};

// ACTIONS
const actions: ActionTree<AppState, any> = {
	setSeoUser({ commit }, value: boolean) {
		commit('SET_SEO_USER', value);
	},

	setXServeTrack({ commit }, value: string | null) {
		commit('SET_X_SERVE_TRACK', value);
	},
};

// MUTATIONS
const mutations: MutationTree<AppState> = {
	SET_SEO_USER(state, value: boolean) {
		state.seoUser = value;
	},

	SET_X_SERVE_TRACK(state, value: string | null) {
		state.xServeTrack = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<AppState, any>;

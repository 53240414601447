import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=SEASON_PAGE_BUYBOX_EXP_V2:control
// ?preferred_variant=SEASON_PAGE_BUYBOX_EXP_V2:variant_1
// ?preferred_variant=SEASON_PAGE_BUYBOX_EXP_V2:variant_2
// ?preferred_variant=SEASON_PAGE_BUYBOX_EXP_V2:variant_3

// ?remove_preferred_experiment=SEASON_PAGE_BUYBOX_EXP_V2

export const SeasonPageBuyBoxExpName = 'SEASON_PAGE_BUYBOX_EXP_V2' as const;

export type SeasonPageBuyBoxExpVariants = ToVariants<typeof SeasonPageBuyBoxExpVariants>;

export const SeasonPageBuyBoxExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
} as const;

export default generate(SeasonPageBuyBoxExpName, SeasonPageBuyBoxExpVariants, instance => {
	const activeRoute = instance.$store?.state?.routing?.activeRoute;
	const lastRoute = instance.$store?.state?.routing?.lastRoute;
	// Only trigger experiment if season page and no previous page has been visited, i.e. direct visit from SERP
	return activeRoute?.name === 'app.detail.show.season' && !lastRoute?.name;
});

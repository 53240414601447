import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import possibleTypes from '@/@types/graphql-fragment-types.json';
import type { ApolloClientConfig } from '@/graphql/utils';

// Install the vue plugin
Vue.use(VueApollo);

const createApolloClient = async ({
	store,
	headers,
}: ApolloClientConfig): Promise<ApolloClient<NormalizedCacheObject>> => {
	const fragmentMatcher = new IntrospectionFragmentMatcher({
		introspectionQueryResultData: possibleTypes,
	});
	// Cache implementation
	const cache = new InMemoryCache({ fragmentMatcher });

	// If on the client, recover the injected state
	if (process.client && window?.__APOLLO_STATE__ != null) {
		cache.restore(window.__APOLLO_STATE__.defaultClient);
	}

	// Create the apollo client
	const ssrConfig = process.server ? { ssrMode: true } : { ssrForceFetchDelay: 500 };
	const connectToDevTools = process.env.NODE_ENV !== 'production';
	let links = [];
	if (process.server) {
		const { getServerLinks } = await import('@/graphql/utils');
		links = await getServerLinks({ store, headers });
	} else {
		const { getClientLinks } = await import('@/graphql/utils');
		links = await getClientLinks({ store, headers });
	}
	return new ApolloClient({
		link: from(links),
		cache,
		connectToDevTools,
		...ssrConfig,
	});
};

let defaultClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getApolloProvider = async ({ store, headers }: ApolloClientConfig) => {
	defaultClient = await createApolloClient({ store, headers });

	return new VueApollo({
		defaultClient,
	});
};

export { defaultClient as apolloClient };

import { toValue, type MaybeRefOrGetter } from '@vueuse/core';
import type { SportType } from '@/@types/graphql-types';
import type { BuyboxParam } from '@/helpers/offer-helper';
import type { SnowplowContext } from '@/helpers/tracking/providers';
import type { ClickoutOfferDetails } from '@/interfaces/snowplow/clickout-context';

import { base64EncodeUrl, btoa } from '@/helpers/base64-helper';
import { isClickoutOfferDetails } from '@/interfaces/snowplow/clickout-context';
import { SnowplowClickoutContextGraphql } from '@/helpers/tracking/providers';
import { TrackingAppId, TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import { useMutation } from '@/helpers/composables/useApollo';

import {
	type GetFinalClickoutUrlMutation,
	type GetFinalClickoutUrlMutationVariables,
	GetFinalClickoutUrlDocument,
} from '@/graphql/mutation/GetFinalClickoutUrl.mutation';

import { getTCString } from './impression-tracker-helper';
import { GDPR_WEB_LOCALES } from '@/constants/web-locales.constant';
import type { WebLocale } from '@/enums/web-locale';
import type { Maybe } from '@/helpers/types';
import { setYieldOptimisationTags } from '@/helpers/promotion-helper';

// AMAZON_OS_FREE_TRIAL_EXP_2
import { tagProviderFreeTrialUrl } from '@/components/experiments/AmazonOSFreeTrialExp2';
// AMAZON_OS_FREE_TRIAL_EXP_2

/**
 * Wrapper around the built-in URL class with a fluent interface for setting
 * query parameters that safely ignores falsy values.
 */
export interface UrlHelper<Parameter = string> {
	/** Reference to the underlying URL class. */
	url: URL;

	/** Holds any error that may have happened during instantiation. */
	error: Error | null;

	/**
	 * Set a URL parameter, if the value is falsy, the parameter will not be added.
	 * **Note that parameter values are automatically encoded.**
	 */
	set<Value extends Maybe<string | number>>(parameter: Parameter, value: Value): UrlHelper<Parameter>;

	/** Get back the value of a parameter on the URL Helper. */
	get(parameter: Parameter): string | null;

	/** Returns the URL as a string. */
	toString(): string;
}

export class ClassicUrl implements UrlHelper {
	#url: URL;
	#error: Error | null = null;

	/**
	 * A helper class around the built-in URL class that offers a fluent
	 * interface (method chaining) for setting query parameters. It
	 * safely ignores null or empty query param values.
	 *
	 * @param urlString A valid string URL or exisiting URL class.
	 */
	constructor(urlString: Maybe<string | URL>) {
		try {
			if (urlString == null) {
				throw new Error('[ClassicUrl] "urlString" must be a string and valid URL.');
			}

			this.#url = new URL(urlString);
		} catch (e: any) {
			console.warn(e.message ?? '[ClassicUrl] The URL String passed to URL Helper is an invalid URL');
			this.#error = e;
		}
	}

	/** Reference to the underlying URL class. */
	get url() {
		return this.#url;
	}

	/** Holds any error that may have happened during instantiation. */
	get error() {
		return this.#error;
	}

	/** Set a URL parameter, if the value is falsy, the parameter will not be added. */
	set(parameter: string, value: Maybe<string | number>): ClassicUrl {
		if (value) {
			this.#url.searchParams.set(parameter, value.toString());
		}

		return this;
	}

	/** Get back the value of a parameter on the Classic URL. */
	get(parameter: string): string | null {
		return this.#url.searchParams.get(parameter);
	}

	/** Returns the Classic URL as a string. */
	toString() {
		return this.#url.toString();
	}
}

// JustWatch UCT with a different subdomain to bypass ad blockers
const CLICK_URL = 'https://e.justwatch.com' as const;

// for WOW, WOW Sport, Sky Go, and Sky Sport we need GDPR information
const WOW_PROVIDER_IDS = [29, 703, 30];
/**
 * TC String is only made after consents have been obtained
 * Since this is only for one provider in one country, this is the tc string when all consents are denied.
 */
const NO_CONSENT_TC_STRING =
	'CQF3YIAQF3YIAAFADBDEBJFgAAAAAEPgAAYgAAAQuAIAFCIgAKIAICCAAAAEAAgrCAigAAAAAECBAAAECABwAgEokIEAAAAAAAAAAABAgACAAAAABCIAAAAAAAAAABAAAgAACAQAECAAAACgAAgABAQAAAAAAAAAAAIAAgBAACAQAAEAIAAAABAEAAAAQACAAEAAAAAIAAQAAAAAAAAAACAAAQIYAAAA';

type Offer = Maybe<{ standardWebURL?: Maybe<string>; package?: Maybe<{ packageId?: number }> }>;
type ClickoutUrlParam =
	| 'cx'
	| 'r'
	| 'sid'
	| 'uct_buybox'
	| 'uct_country'
	| 'uct_ct'
	| 'uct_ua'
	| 'uct_title'
	| 'uct_itsct'
	| 'uct_sport'
	| 'uct_tryout'
	| 'uct_web_app_version'
	| 'utm_campaign'
	| 'utm_medium'
	| 'utm_source'
	| 'uct_sr'
	| 'uct_ft';

export class ClickoutUrl implements UrlHelper<ClickoutUrlParam> {
	#url: ClassicUrl;
	#error: Error | null = null;
	#providerId: number | null;

	/**
	 * A helper class for Clickout URLs (links that pass through JW UCT).
	 * It uses a fluent interface (method chaining) and handles adding contexts,
	 * default tracking, and null or empty parameter values
	 *
	 * @param offer Any object that, at least, has "standardWebURL"
	 * @param options Additional, optional configuration for a clickout url
	 * @param options.fallback URL to use if standardWebURL is falsy
	 * @param isQualityTV boolean to check if clickoutURL is QTV
	 * @param options.country Country to check for UCT bypassing, if not given it will use `uct_country`
	 */
	constructor(
		offer: Offer | (Offer & ClickoutOfferDetails),
		{ fallback = '' }: { fallback?: string } = {},
		isQualityTV: boolean = false,
		isFreeTrial: boolean = false
	) {
		const clickUrl = JW_CONFIG.CLICK_URL.includes('moviecycle') ? JW_CONFIG.CLICK_URL : CLICK_URL;

		this.#url = new ClassicUrl(`${clickUrl}/a`);
		this.#error = this.#url.error;

		// adds app version information to the URL as a query parameter
		this.set('uct_web_app_version', TrackingAppId);

		// May need to be encoded due to click tags provided by clients
		this.set('r', offer?.standardWebURL ?? fallback);

		// Yield optimisation tags
		setYieldOptimisationTags(this, offer?.package?.packageId ?? 0, isQualityTV);
		// Yield optimisation title tag for ATV+
		const titleContext = TrackingHelper.getAdditionalContexts().find((context: SnowplowContext) => {
			return context.__name === 'title_v2';
		});
		if (titleContext && offer?.package?.packageId === 350) this.set('uct_title', (titleContext as any).jwEntityId);

		// Tracking Contexts
		this.addContexts(TrackingHelper.getAdditionalContexts(), isFreeTrial);

		if (isClickoutOfferDetails(offer)) {
			this.#providerId = offer.package.packageId;
			this.addContexts(SnowplowClickoutContextGraphql.fromProviderOffer(offer), isFreeTrial);
		}
	}

	/** Reference to the underlying URL class. */
	get url() {
		return this.#url.url;
	}

	/** Returns any error that may have happened during instantiation. */
	get error() {
		return this.#error;
	}

	get providerId() {
		return this.#providerId;
	}

	/**
	 * Set a URL parameter, if the value is falsy, the parameter will not be added.
	 * **Note that parameter values are automatically encoded.**
	 */
	set(parameter: 'r', value: string): ClickoutUrl;
	set(parameter: 'cx', value: string): ClickoutUrl;
	/** Domain session ID */
	set(parameter: 'sid', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_buybox', value: BuyboxParam): ClickoutUrl;
	set(parameter: 'uct_country', value: string): ClickoutUrl;
	set(parameter: 'uct_ct', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_ua', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_title', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_itsct', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_sport', value: Maybe<SportType>): ClickoutUrl;
	set(parameter: 'uct_tryout', value: number): ClickoutUrl;
	set(parameter: 'utm_campaign', value: string): ClickoutUrl;
	set(parameter: 'utm_medium', value: string): ClickoutUrl;
	set(parameter: 'utm_source', value: string): ClickoutUrl;
	set(parameter: 'uct_web_app_version', value: string): ClickoutUrl;
	set(parameter: 'uct_sr', value: string): ClickoutUrl;
	set(parameter: 'uct_ft', value: string): ClickoutUrl;
	set(parameter: ClickoutUrlParam, value: Maybe<string | number>): ClickoutUrl {
		this.#url.set(parameter, value?.toString());

		return this;
	}

	/** Set a URL parameter but directly on the redirect offer link. */
	setOnOffer(parameter: string, value: Maybe<string | number>): ClickoutUrl {
		const offer = this.get('r');
		if (value == null || offer === null) return this;

		return this.set('r', new ClassicUrl(offer).set(parameter, value).toString());
	}

	/** Get back the value of a parameter on the Clickout URL. */
	get(parameter: ClickoutUrlParam) {
		return this.#url.get(parameter);
	}

	#trackingContexts: SnowplowContext[] = [];

	/** The tracking contexts saved on the Clickout URL. */
	get contexts() {
		return this.#trackingContexts;
	}

	/**
	 * Helper for setting additional contexts, already includes `TrackingHelper.getAdditionalContexts`
	 *
	 * @param contexts Reactive single or array of SnowplowContexts to include in the URL
	 */
	addContexts(contexts: MaybeRefOrGetter<Maybe<SnowplowContext | SnowplowContext[]>> = [], isFreeTrial = false) {
		const newContexts = toValue(contexts);

		if (newContexts != null) {
			Array.isArray(newContexts)
				? this.#trackingContexts.push(...newContexts)
				: this.#trackingContexts.push(newContexts);
		}

		// AMAZON_OS_FREE_TRIAL_EXP_2
		if (isFreeTrial) tagProviderFreeTrialUrl(this, this.providerId ?? 0);
		// AMAZON_OS_FREE_TRIAL_EXP_2

		return this;
	}

	/** Returns the Clickout URL as a string. */
	toString() {
		this.set('cx', encodeContexts(this.#trackingContexts));

		// for WOW, WOW Sport, Sky Go, and Sky Sport we need GDPR information
		const country = this.get('uct_country')?.toLowerCase() as WebLocale;
		if (country === 'de' && this.providerId && WOW_PROVIDER_IDS.includes(this.providerId)) {
			this.setOnOffer('gdpr', GDPR_WEB_LOCALES.includes(country) ? '1' : '0');
			this.setOnOffer('gdpr_consent', getTCString() ?? NO_CONSENT_TC_STRING);
		}

		return this.#url.toString();
	}
}

const MUTATION_TIMEOUT = 500 as const;

const { mutate, loading, onError } = useMutation<GetFinalClickoutUrlMutation, GetFinalClickoutUrlMutationVariables>(
	GetFinalClickoutUrlDocument
);

export const skipUCTCountries = ['CA', 'AU', 'BR', 'IT', 'MX', 'AR'];
/** Given an original clickout URL, get the final redirect value that UCT would calculate. */
export async function handleClickoutUrl(clickoutUrl: string, enable = false) {
	if (process.server && (process.env.NODE_ENV === 'development' || JW_CONFIG.DOMAIN === 'moviecycle.com')) {
		console.warn('[ClickoutHandler] Do not use Clickout Handler on the server!');
	}

	console.log(enable);

	// If disabled globally or locally then don't skip UCT and use the original URL.
	if (!enable) {
		safeOpen(clickoutUrl);
		return;
	}

	// URL is not meant to be affiliated, it already skips UCT
	if (!clickoutUrl.includes(CLICK_URL) && !clickoutUrl.includes(JW_CONFIG.CLICK_URL)) {
		safeOpen(clickoutUrl);
		return;
	}

	try {
		// prettier-ignore
		onError(error => { throw error });

		const windowReference = window.open(undefined, '_blank', 'nofollow sponsored popup=false');

		const mutation = await mutate({ input: clickoutUrl });
		if (mutation?.data == null) throw new Error('Mutation did not return any data.');

		const timeOut = setTimeout(() => {
			if (loading.value === true) throw new Error(`Took over ${MUTATION_TIMEOUT}ms to get final URL`);

			clearTimeout(timeOut);
		}, MUTATION_TIMEOUT);

		safeOpen(mutation.data.getFinalClickoutUrl.url, windowReference);
	} catch (error: any) {
		captureMessageForSentry(
			`[ClickoutHelper] ${error?.message ?? 'Error getting the final Clickout URL.'}`,
			{ where: '[ClickoutHelper] handleClickout', error, meta: { clickoutUrl } },
			'error',
			{ UCT: 'Query' }
		);

		safeOpen(clickoutUrl);
	}
}

/** Safari on iOS may block `window.open()` made inside an async call so we use promises. */
export function safeOpen(url: UrlHelper | string, _windowReference: Window | null = null) {
	const clickoutUrl = url.toString();

	// if argument 3 (AKA `windowFeatures`) has anything other than just 'noopener', it'll open as a popup
	const windowReference = _windowReference ?? window.open(undefined, '_blank', 'nofollow sponsored popup=false');

	if (windowReference) {
		windowReference.location = clickoutUrl;
	} else {
		window.open(clickoutUrl, '_blank', 'nofollow sponsored popup=false');

		captureMessageForSentry(
			`[SafeOpen] New tab opened without reference.`,
			{
				where: '[clickout-helper.ts] safeOpen()',
				error: new Error('New tab opened without reference.'),
				meta: { clickoutUrl },
			},
			'error',
			{ UCT: 'Window Open' }
		);
	}
}

function encodeContexts(contexts: SnowplowContext[]): string {
	const typeList = new Set<string>();

	const completeContext = {
		schema: 'iglu:com.snowplowanalytics.snowplow/contexts/jsonschema/1-0-0',
		data: contexts
			.filter(c => c != null)
			.filter(({ schema }) => !typeList.has(schema) && typeList.add(schema))
			.map(c => c.toObject()),
	};

	try {
		const contextString = JSON.stringify(completeContext);
		return base64EncodeUrl(btoa(decodeURIComponent(encodeURIComponent(contextString))));
	} catch (error) {
		console.error('[OfferHelper] encodeContexts: Failed to generate encoded context string: ', error);
		return '';
	}
}

import { getVm } from '@/helpers/vm-helper';
import SeasonPageBuyBoxExp, { SeasonPageBuyBoxExpName, SeasonPageBuyBoxExpVariants } from '.';
import { ExperimentTrackEventOptions } from '../Core/types';

export const trackSeasonPageBuyBoxExp = (
	TrackingOptions: ExperimentTrackEventOptions<SeasonPageBuyBoxExpVariants>,
	lastRoute = false
) => {
	const vm = getVm();
	const activeVariant =
		vm?.$store.getters['experiment/activeVariantsWithControlGroup'][SeasonPageBuyBoxExpName] ?? null;
	const isUserLoggedIN = !!vm?.$store.getters['user/isLoggedIn'];
	const lastRouteName = vm?.$store.state.routing.lastRoute?.name;

	const routeName = lastRoute ? lastRouteName : vm?.$route.name;
	if (activeVariant && !isUserLoggedIN && (routeName === 'app.detail.show.season' || lastRoute)) {
		SeasonPageBuyBoxExp.trackEvent({
			property: 'season',
			...TrackingOptions,
		});
	}
};
